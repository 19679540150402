exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-ahamo-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/ahamo/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-ahamo-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-biglobe-mobile-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/biglobe-mobile/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-biglobe-mobile-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-d-3-wifi-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/d3wifi/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-d-3-wifi-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-gmotokutokubb-wimax-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/gmotokutokubb-wimax/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-gmotokutokubb-wimax-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-his-mobile-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/his-mobile/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-his-mobile-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-jcom-mobile-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/jcom-mobile/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-jcom-mobile-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-linemo-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/linemo/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-linemo-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-mineo-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/mineo/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-mineo-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-nihontuushin-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/nihontuushin/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-nihontuushin-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-ocnmobileone-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/ocnmobileone/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-ocnmobileone-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-rakuten-mobile-home-wifi-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/rakuten-mobile/home-wifi/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-rakuten-mobile-home-wifi-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-rakuten-mobile-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/rakuten-mobile/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-rakuten-mobile-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-rakuten-mobile-pocket-2-b-2-c-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/rakuten-mobile/pocket2b2c/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-rakuten-mobile-pocket-2-b-2-c-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-uq-mobile-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/uq-mobile/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-uq-mobile-index-mdx" */),
  "component---src-templates-article-jsx-content-file-path-src-posts-ymobile-index-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/home/openista1/new-website-gatsby/gatsby5blog/daijiro21.com/src/posts/ymobile/index.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-src-posts-ymobile-index-mdx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */)
}

